import React, { useState } from 'react';
import { Button, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fileInput: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(2, 0, 1),
  },
}));

function App() {
  const classes = useStyles();
  const [excelFile, setExcelFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExcelFile(file);
  };

  const convertAndDownload = () => {
    if (!excelFile) {
      alert('Please choose an Excel file.');
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const arrayBuffer = e.target.result;
      const data = new Uint8Array(arrayBuffer);

      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const urlList = [];

      jsonData.forEach((row, index) => {
        if (index === 0) {
          return false;
        }
        if (jsonData[index][0]) {
          const blob = new Blob([row[1]], { type: 'text/plain' });
          const url = URL.createObjectURL(blob);
          urlList.push({ url, episode_name: row[0] });
        }
      });

      saveZip('Transcription-Txt', urlList, '.txt');
    };

    reader.readAsArrayBuffer(excelFile);
  };

  const saveZip = (filename, urls, ext) => {
    if (!urls?.length) return;

    const zip = new JSZip();
    const folder = zip.folder('files');

    urls.forEach((item) => {
      const blobPromise = fetch(item.url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });

      const name = item.episode_name + ext;
      folder.file(name, blobPromise);
    });

    zip.generateAsync({ type: 'blob' }).then((blob) => FileSaver.saveAs(blob, filename));
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h5">
            Excel to Text Converter
          </Typography>
          <input
            type="file"
            accept=".xls, .xlsx"
            id="fileInput"
            className={classes.fileInput}
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput">
            <Button
              variant="contained"
              color="primary"
              component="span"
              fullWidth
              className={classes.button}
            >
              Upload Excel File
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            onClick={convertAndDownload}
            disabled={!excelFile}
            fullWidth
            className={classes.button}
          >
            Convert and Download
          </Button>
        </Paper>
      </div>
    </Container>
  );
}

export default App;
